import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdbreact';
import DataLayer from '../Utilities/DataLayer';

export default class AdminPanel extends React.Component {

    config = [];

    constructor() {
        super();
        this.state = {
            config: this.config
        }
        this.dataLayer = new DataLayer();
    }

    componentWillMount() {
        this.loadConfig();
    }

    async loadConfig() {
        this.config = await this.dataLayer.Get('/getconfig');
        console.log(this.config);
        this.setState({ config: this.config });
    }

    render() {
        return (
            <div>
                <h1>Admin Panel</h1>
                {
                    this.config.map((configEntry) => {
                        return (
                            <MDBCard className="mb-3">
                                <MDBCardBody>
                                    <MDBBtn className="float-right">Add</MDBBtn>
                                    <MDBCardTitle>{configEntry.id.toUpperCase()}</MDBCardTitle>
                                    <MDBCardText>
                                        {
                                            configEntry.items.map((item) => {
                                                return (<p>{item.value}</p>);
                                            })
                                        }
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        );
                    })
                }
            </div>
        );
    }
}