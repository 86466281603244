import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogTicket from '../Components/LogTicket';
import EscalatedTicket from '../Components/EscalatedTicket';
import AdminPanel from '../Components/AdminPanel';
import Report from '../Components/Report';

const ComponentRoutes = (props) => {
    let localProps = props;
    return (
        <Switch>
            <Route path="/workspace/logticket" render={(props) => <LogTicket {...props} notify={localProps.notify} />} />
            <Route path="/workspace/escalatedticket" render={(props) => <EscalatedTicket {...props} notify={localProps.notify} />}/>
            <Route path="/workspace/adminpanel"render={(props) => <AdminPanel {...props} notify={localProps.notify} />} />
            <Route path="/workspace/report" render={(props) => <Report {...props} notify={localProps.notify} />} />
            render={function () {
                return <h1>Not Found</h1>;
            }}
            />
        </Switch>
    );
}

export default ComponentRoutes