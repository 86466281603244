import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import Header from './Header';
import ComponentRoutes from '../Utilities/ComponentRoutes';
import Notification from '../Components/Notification';

export default class AgentWorkspace extends React.Component {

    constructor() {
        super();
        this.state = {
            notificationType: '',
            notificationMessage: '',
            notificationShow: false
        }

        this.notify = this.notify.bind(this);
    }

    notify(type, message) {
        this.setState({
            notificationShow: false
        });
        this.setState({
            notificationType: type,
            notificationMessage: message,
            notificationShow: true
        });
    }

    render() {
        return (
            <Router>
                <Header />

                {
                    this.state.notificationShow ? (<Notification type={this.state.notificationType} message={this.state.notificationMessage} />) : (<div></div>)
                }
                
                <MDBContainer>
                    <ComponentRoutes notify={this.notify} />
                </MDBContainer>
            </Router>
        );
    }
}