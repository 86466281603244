import React from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import DataLayer from '../Utilities/DataLayer';

export default class Report extends React.Component {

    reportData = [{
        "dummy": "dummy"
    }];

    constructor(props) {
        super(props);
        this.state = props;

        this.loadReportData = this.loadReportData.bind(this);
        this.logState = this.logState.bind(this);
        this.dataLayer = new DataLayer();
    }

    componentWillMount() {
        this.loadReportData();
    }

    async loadReportData() {
        this.reportData = await this.dataLayer.Get('/getopentickets');
        this.setState({ staticContext: this.state.staticContext });
    }

    logState() {
        console.log(this.state);
    }

    render() {
        this.logState();
        return (
            <div>
                <h3 className="mb-3">Report</h3>
                <PivotTableUI
                    data={this.reportData}
                    onChange={s => this.setState(s)}
                    {...this.state}
                />
            </div>
        );
    }
}