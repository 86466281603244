import React from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBIcon } from "mdbreact";

export default class Header extends React.Component {
    state = {
        isOpen: false
    };

    render() {
        return (
            <MDBNavbar color="stylish-color" dark expand="md" className="mb-4">
                <MDBNavbarBrand>
                    <strong className="white-text">FoneBook</strong>
                </MDBNavbarBrand>
                <MDBNavbarNav left>
                    <MDBNavItem>
                        <MDBNavLink to="/workspace/logticket">Log Ticket</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink to="/workspace/escalatedticket">Escalated Tickets</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink to="/workspace/report">Report</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink to="/workspace/adminpanel">Admin Panel</MDBNavLink>
                    </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                    <MDBNavItem>
                        <MDBNavLink className="waves-effect waves-light" to="#!">
                            <MDBIcon icon="sign-out-alt" />
                        </MDBNavLink>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBNavbar>
        );
    }
}