import React from 'react';
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBBadge } from 'mdbreact';
import DataLayer from '../Utilities/DataLayer';

export default class EscalateTicket extends React.Component {

    client = {
        id: "",
        name: "",
        surname: "",
        cell: "",
        gender: "",
        dateOfBirth: undefined,
        saCitizen: true,
    }

    ticket = {
        agentId: "",
        clientId: "",
        storeId: "",
        storeAgent: "",
        status: "",
        opened: "",
        closed: "",
        reason: "",
        resolution: "",
        escalatedId: "",
        notes: []
    }

    constructor(props) {
        super();

        this.state = {
            client: this.client,
            ticket: this.ticket,
            ticketCount: 0
        }

        this.dataLayer = new DataLayer();
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.loadTicket();    
    }

    async loadTicket() {
        let allOpenTickets = await this.dataLayer.Get('/getopentickets');

        if (allOpenTickets == null) {
            this.props.notify('success', 'No tickets available');
            const { history } = this.props;
            history.push('/workspace');
            return;
        }

        this.setState({
            ticketCount: allOpenTickets.length
        });
        this.ticket = allOpenTickets.filter((ticket) => ticket.escalatedId === sessionStorage.getItem('foneBookUser'));

        if (this.ticket.clientId == null) {
            this.ticket = allOpenTickets[0];
        }

        this.client = await this.dataLayer.Get(`/getclientdetails/${this.ticket.clientId}`);
        this.client.dateOfBirth = this.client.dateOfBirth.substring(0, 10);

        if (this.ticket.notes === undefined) {
            this.ticket.notes = [];
        }

        this.setState({
            client: this.client,
            ticket: this.ticket
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        this.ticket.resolution = event.target.resolution.value;
        this.ticket.notes.push(`[${sessionStorage.getItem('foneBookUser')}] ${event.target.note.value}`);
        this.ticket.closed = new Date();
        this.ticket.status = 'closed';
        await this.dataLayer.Post('/updateticket', this.ticket);
        this.loadTicket();
    }

    render() {
        return (
            <div>
                <MDBRow between>
                    <MDBCol>
                        <h3 className="mb-3">Escalated Ticket</h3>
                    </MDBCol>
                    <MDBCol className="text-right">
                        <h4>
                            <MDBBadge color="info">{this.state.ticketCount} Open</MDBBadge>
                        </h4>
                    </MDBCol>
                </MDBRow>
                

                {/* STORE AGENTS */}
                <MDBInput
                    label="Store"
                    type="text"
                    value={this.ticket.storeId}
                    disabled
                />

                <MDBInput
                    label="Store Agent"
                    type="text"
                    value={this.ticket.storeAgent}
                    disabled
                />

                {/* CUSTOMER INFORMATION */}
                <MDBInput
                    name="id"
                    label="Customer ID / Passport Number"
                    type="text"
                    value={this.client.id}
                    disabled
                />
                <input
                    name="saCitizen"
                    label="SA Citizen"
                    type="checkbox"
                    checked={this.client.saCitizen}
                    disabled
                /> SA Citizen
                <MDBInput
                    name="name"
                    label="First Name"
                    type="text"
                    value={this.client.name}
                    disabled
                />
                <MDBInput
                    name="surname"
                    label="Surname"
                    type="text"
                    value={this.client.surname}
                    disabled
                />
                <MDBInput
                    name="dateOfBirth"
                    label="Date of Birth"
                    type="date"
                    value={this.client.dateOfBirth}
                    disabled
                />
                <MDBInput
                    name="gender"
                    label="Gender"
                    type="text"
                    value={this.client.gender}
                    disabled
                />
                <MDBInput
                    name="cell"
                    label="Cellphone Number"
                    type="text"
                    value={this.client.cell}
                    disabled
                />
                <MDBInput
                    name="open"
                    label="Date Opened"
                    type="text"
                    value={this.ticket.opened}
                    disabled
                />
                <MDBInput
                    name="reason"
                    label="Ticket Reason"
                    type="text"
                    value={this.ticket.reason}
                    disabled
                />

                <h5>Notes</h5>
                {
                    this.ticket.notes.map((note) => (<p><i>{note}</i></p>))
                }

                <form onSubmit={this.onSubmit}>
                    <MDBInput
                        name="resolution"
                        label="Resolution"
                        type="text"
                        required
                    />
                    <MDBInput
                        label="Note"
                        type="textarea"
                        name="note"
                        required
                    />

                    <MDBBtn type="submit">Resolve Ticket</MDBBtn>
                </form>
            </div>
        );
    }
}