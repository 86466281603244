import axios from 'axios';
import AppSettings from '../appsettings';

export default class DataLayer {

    constructor() {
    }

    async Get(path) {
        try {
            let response = await axios.get(`${AppSettings.serverEndpoint}${path}`, this.setHeaders());
            return response.data;
        } catch (exception) {
            if (exception.response.status === 500) {
                alert(`An error occurred. ${exception.response.data.message}`);
            }
            else {
                alert(`An error occurred. ${exception}`);
            }
        }
    }

    async Post(path, object) {
        try {
            let response = await axios.post(`${AppSettings.serverEndpoint}${path}`, JSON.stringify(object), this.setHeaders());
            return response.data;
        } catch (exception) {
            if (exception.response.status === 500) {
                alert(`An error occurred. ${exception.response.data.message}`);
            }
            else {
                alert(`An error occurred. ${exception}`);
            }
        }
    }

    setHeaders() {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    }
}