import React from 'react';
import { MDBBtn, MDBInput, MDBRow, MDBCol } from 'mdbreact';
import SelectSearch from 'react-select-search';
import DataLayer from '../Utilities/DataLayer';

export default class LogTicket extends React.Component {

    client = {
        id: "",
        name: "",
        surname: "",
        cell: "",
        gender: "",
        dateOfBirth: undefined,
        saCitizen: true,
    }

    ticket = {
        agentId: sessionStorage.getItem("foneBookUser"),
        clientId: "",
        storeId: "",
        storeAgent: "",
        status: "",
        opened: new Date(),
        closed: "",
        reason: "",
        resolution: "",
        escalatedId: "",
        notes: []
    }

    constructor(props) {
        super();

        this.state = {
            client: this.client,
            ticket: this.ticket,
            storeAgent: false,
            showCusomerDetail: false,
            storeList: [],
            storeAgentList: [],
            reasonsList: [],
            resolutionsList: [],
            newCustomer: false,
            escalate: false
        }

        this.onIDLookup = this.onIDLookup.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.dataLayer = new DataLayer();
        this.processChangeEvent = this.processChangeEvent.bind(this);
    }

    componentWillMount() {
        let callCenterConfig = JSON.parse(sessionStorage.getItem('foneBookConfig'));

        if (callCenterConfig != null) {
            //Load stores from config
            let configStores = callCenterConfig.find((config) => config.id === 'stores');
            let storeList = [];
            configStores.items.forEach((store) => {
                storeList.push({ 'name': store.value, 'value': store.key });
            });
            storeList = storeList.sort((a, b) => {
                if (a.name > b.name) { return 1; }
                if (a.name < b.name) { return -1; }
                return 0;
            })

            //Load reasons from config
            let configReasons = callCenterConfig.find((config) => config.id === 'reasons');
            let reasonsList = [];
            reasonsList.push({ 'name': 'Other', 'value': 'Other' });
            configReasons.items.forEach((reason) => {
                reasonsList.push({ 'name': reason.value, 'value': reason.key });
            });
            reasonsList = reasonsList.sort((a, b) => {
                if (a.name > b.name) { return 1; }
                if (a.name < b.name) { return -1; }
                return 0;
            })

            //Load resolutions from config
            let configResolutions = callCenterConfig.find((config) => config.id === 'resolutions');
            let resolutionsList = [];
            resolutionsList.push({ 'name': 'Other', 'value': 'Other' });
            configResolutions.items.forEach((resolution) => {
                resolutionsList.push({ 'name': resolution.value, 'value': resolution.key });
            });
            resolutionsList = resolutionsList.sort((a, b) => {
                if (a.name > b.name) { return 1; }
                if (a.name < b.name) { return -1; }
                return 0;
            })

            this.setState({ storeList, reasonsList, resolutionsList });
        }
    }

    processChangeEvent() {
        this.setState({
            client: this.client,
            ticket: this.ticket
        });
    }

    async loadAgents(selectedItem, state, props) {
        let store = await this.dataLayer.Get(`/getstoredetails/${selectedItem.value}`);
        let storeAgentList = [];

        this.ticket.storeId = selectedItem.value;

        if (store !== undefined)
        {
            store.agents.forEach((agent) => {
                storeAgentList.push({ 'name': agent, 'value': agent });
            });

            storeAgentList = storeAgentList.sort((a, b) => {
                if (a.name > b.name) { return 1; }
                if (a.name < b.name) { return -1; }
                return 0;
            })
            this.setState({ storeAgentList });
        }
    }

    async onIDLookup() {
        let clientLookupResult = await this.dataLayer.Get(`/getclientdetails/${this.client.id}`)

        console.log(clientLookupResult);

        if (clientLookupResult != null) {
            this.client = clientLookupResult;
            this.client.dateOfBirth = this.client.dateOfBirth.substring(0, 10);

            this.setState({
                client: this.client,
                showCusomerDetail: true,
                newCustomer: false
            });
        }
        else {
            this.setState({
                showCusomerDetail: true,
                newCustomer: true
            });
        }
    }

    validateSubmissionData(form) {

        if (form.callReason.value === '') {
            this.props.notify('error', 'Call reason is required');
            return false;
        }

        return true;
    }

    async onSubmit(event) {
        event.preventDefault();

        if (event.key === 13) {
            return;
        }

        if (this.validateSubmissionData(event.target)) {

            if (this.state.newCustomer) {
                this.client._id = null;
                this.client.dateOfBirth = new Date(this.client.dateOfBirth);
                await this.dataLayer.Post('/addclient', this.client);
            } else {
                this.client.dateOfBirth = new Date(this.client.dateOfBirth);
                //await this.dataLayer.Post('/updateclient', this.client);
            }

            if (event.target.note !== null && event.target.note.value.trim() !== '') {
                this.ticket.notes.push(`[${sessionStorage.getItem("foneBookUser")}] ${event.target.note.value}`);
            }

            this.ticket.clientId = this.client.id;

            //Set Status
            if (this.state.escalate) {
                this.ticket.closed = new Date('1900-01-01');
                this.ticket.status = 'escalated';
            }
            else {
                this.ticket.closed = new Date();
                this.ticket.status = 'closed';
            }

            let result = await this.dataLayer.Post('/addticket', this.ticket);
            if (result != null) {
                this.props.notify('success', 'Ticket successfully saved');
                const { history } = this.props;
                history.push('/workspace');
            }
            else {
                this.props.notify('error', 'An error occurred');
            }
        }
    }

    render() {
        return (
            <div>
                <h3 className="mb-3">New Ticket</h3>
                <form onSubmit={this.onSubmit}>
                    {/* SELECT CALLER TYPE */}
                    <div className="form-group">
                        <input
                            type="radio"
                            name="storeAgent"
                            value={true}
                            onChange={() => this.setState({ storeAgent: true })}
                            required
                        /> Store Agent <br />

                        <input
                            type="radio"
                            name="storeAgent"
                            value={false}
                            onChange={() => this.setState({ storeAgent: false })}
                            required
                        /> Private Customer
                    </div>

                    {/* SEARCH STORE AGENTS */}
                    <div className="form-group">
                        {
                            (this.state.storeAgent) ?
                                (
                                    <div>
                                        <SelectSearch
                                            name="storeId"
                                            options={this.state.storeList}
                                            placeholder="Select a Store"
                                            search={true}
                                            onChange={(selectedItem, state, props) => this.loadAgents(selectedItem, state, props)}
                                            value={this.ticket.storeId}
                                        />
                                        <SelectSearch
                                            name="storeId"
                                            options={this.state.storeAgentList}
                                            placeholder="Select an Agent"
                                            search={true}
                                            onChange={(selectedItem, state, props) => {
                                                this.ticket.storeAgent = selectedItem.value;
                                                this.processChangeEvent();
                                            }}
                                            value={this.ticket.storeAgent}
                                        />
                                    </div>
                                )
                                : (<div></div>)
                        }
                    </div>

                    {/* SEARCH CUSTOMER */}
                    <MDBRow>
                        <MDBCol md="8">
                            <MDBInput
                                name="id"
                                label="Customer ID / Passport Number"
                                type="text"
                                value={this.client.id}
                                onChange={(event) => {
                                    this.client.id = event.target.value;
                                    this.processChangeEvent();
                                }}
                                maxLength="13"
                                minLength="10"
                                validate
                                required
                            />
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBBtn onClick={this.onIDLookup}> Lookup Client</MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* CUSTOMER & TIKCET INFORMATION */}
                    {
                        (this.state.showCusomerDetail) ?
                            (
                                <div className="form-group">
                                    <input
                                        name="saCitizen"
                                        label="SA Citizen"
                                        type="checkbox"
                                        checked={this.client.saCitizen}
                                        onChange={(event) => {
                                            this.client.saCitizen = event.target.checked;
                                            this.processChangeEvent();
                                        }}
                                    /> SA Citizen
                                    <MDBInput
                                        name="name"
                                        label="First Name"
                                        type="text"
                                        value={this.client.name}
                                        onChange={(event) => {
                                            this.client.name = event.target.value;
                                            this.processChangeEvent();
                                        }}
                                        required
                                    />
                                    <MDBInput
                                        name="surname"
                                        label="Surname"
                                        type="text"
                                        value={this.client.surname}
                                        onChange={(event) => {
                                            this.client.surname = event.target.value;
                                            this.processChangeEvent();
                                        }}
                                        required
                                    />
                                    <MDBInput
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                        type="date"
                                        value={this.client.dateOfBirth}
                                        onChange={(event) => {
                                            this.client.dateOfBirth = event.target.value;
                                            this.processChangeEvent();
                                        }}
                                        required
                                    />
                                    <SelectSearch
                                        name="gender"
                                        options={[{ 'name': 'Male', 'value': 'Male' }, { 'name': 'Female', 'value': 'Female' }]}
                                        placeholder="Select a Gender"
                                        search={false}
                                        value={this.client.gender}
                                        onChange={(selectedItem, state, props) => {
                                            this.client.gender = selectedItem;
                                            this.processChangeEvent();
                                        }}
                                        required
                                    />
                                    <MDBInput
                                        name="cell"
                                        label="Cellphone Number"
                                        type="text"
                                        value={this.client.cell}
                                        onChange={(event) => {
                                            this.client.cell = event.target.value;
                                            this.processChangeEvent();
                                        }}
                                        maxLength="10"
                                        required
                                    />

                                    {/* TICKET INFORMATION */}
                                    <SelectSearch
                                        name="callReason"
                                        options={this.state.reasonsList}
                                        placeholder="Select a Call Reason"
                                        search={false}
                                        onChange={(selectedItem, state, props) => {
                                            this.ticket.reason = selectedItem.value;
                                            this.processChangeEvent();
                                        }}
                                        value={this.ticket.reason}
                                    />

                                    {
                                        (this.ticket.reason === 'Other') ?
                                            (
                                                <MDBInput
                                                    label="Fault Description"
                                                    name="callReasonDescription"
                                                    onChange={(event) => {
                                                        this.ticket.reason = event.target.value;
                                                        this.processChangeEvent();
                                                    }}
                                                />
                                            ) :
                                            (
                                                <div></div>
                                            )
                                    }

                                    <SelectSearch
                                        options={this.state.resolutionsList}
                                        placeholder="Select a Resolution"
                                        search={false}
                                        name="callResolution"
                                        value={this.ticket.resolution}
                                        onChange={(selectedItem, state, props) => {
                                            this.ticket.resolution = selectedItem.value;
                                            this.processChangeEvent();
                                        }}
                                        required
                                    />

                                    {
                                        (this.ticket.resolution === 'Other') ?
                                            (
                                                <MDBInput
                                                    label="Resolution Description"
                                                    name="callResolutionDescription"
                                                    onChange={(event) => {
                                                        this.ticket.resolution = event.target.value;
                                                        this.processChangeEvent();
                                                    }}
                                                />
                                            ) :
                                            (
                                                <div></div>
                                            )
                                    }

                                    <input
                                        name="escalate"
                                        label="Escalate"
                                        type="checkbox"
                                        value={this.state.escalate}
                                        onChange={(event) => {
                                            this.setState({ escalate: event.target.checked });
                                        }}
                                    /> Escalate

                                    <MDBInput
                                        label="Note"
                                        type="textarea"
                                        name="note"
                                    />

                                    {
                                        this.state.escalate ?
                                            (
                                                <MDBBtn type="submit">Escalate Ticket</MDBBtn>
                                            ) : (
                                                <MDBBtn type="submit">Resolve Ticket</MDBBtn>
                                            )
                                    }
                                </div>
                            ) : (<div></div>)
                    }
                </form>
            </div>
        );
    }
}